

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import { CustomText } from "../../components/text";

class TeacherIntroSection extends React.Component {
    render = () => {
        return (
            <TeacherIntroSectionContainer to="/teacher">
                <CustomText fontSize="30px" fontMargin="20px">
                    Can you teach anything?

                </CustomText>

                <Link to="/teacher">
                    Find out what Skideo has for you
                </Link>

            </TeacherIntroSectionContainer>
        )
    }
}

export default TeacherIntroSection


const TeacherIntroSectionContainer = styled(Link)`
    padding: 8em 0;
    margin: 2em 10%;
    border-radius: 5px;
    text-decoration: none;

    text-align: center;
    a{
        font-size: 20px;
        text-decoration: underline;
        color: #1d1d1f;
    }

    @media only screen and (max-width: 800px) {
        padding: 2em 10px;
        margin: 1em;
    }

    background: #f7f7f8;
    background-image: linear-gradient(90deg, rgb(13, 141, 81) 0%, #f7f7f8);
`