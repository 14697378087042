import React from "react";
import styled from "styled-components";
import { CustomText } from "../../components/text";


class OurMission extends React.Component {

    render() {
        return (
            <OurMissionContainer>
                <CustomText fontColor="white" fontPadding="0.5em">
                    Our mission
                </CustomText>
                <CustomText fontColor="white" fontSize="2em" fontPadding="0.5em">
                    {/* Make quality education easily and readily accessible through technology. */}
                    To decentralize educational resources by connecting teachers and students across Africa.
                </CustomText>
            </OurMissionContainer>
        )
    }
}

export default OurMission

const OurMissionContainer = styled.div`
    margin: 1em auto;
    width: 90%;
    padding: 2em;
    text-align: center;
    background: black;
    border-radius: 5px;


`