import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Marginer } from "../../components/marginer";
import { OurSerivce } from "../../components/ourService";

import LimitlessIllustration from "../../images/illustrations/limitless.png";
import LeverageIllustration from "../../images/illustrations/leverage.svg";
import ConvenienceIllustration from "../../images/illustrations/convenience.svg";
import { CustomText } from "../../components/text";


export function ServicesSection(props) {
  return (
    <ServicesContainer name="servicesSection">
      <CustomText className="sectionTitle">Welcome to Skideo</CustomText>
      <Marginer direction="vertical" margin="3em" />
      <OurSerivce
        title="Convenient"
        description="Study wherever and whenever from the best."
        imgUrl={ConvenienceIllustration}
        imgWidth="250px"
      />
      <OurSerivce
        title="Interactive"
        description="Harness the power of technology to acquire the best education."
        imgUrl={LeverageIllustration}
        imgWidth="250px"
        isReversed
      />
      <OurSerivce
        title="Limitless"
        description="Access a global platform of students, teachers and educational content."
        imgUrl={LimitlessIllustration}
        imgWidth="400px"
      />
    </ServicesContainer>
  );
}



const ServicesContainer = styled(Element)`
  width: 100%;
  min-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0; 

  .sectionTitle{
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    width: 100%;
    text-align:center;
    padding: 2em;

    background: hsla(333, 100%, 53%, 1);

    background: linear-gradient(0deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);

    background: -moz-linear-gradient(0deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);

    background: -webkit-linear-gradient(0deg, hsla(333, 100%, 53%, 1) 0%, hsla(33, 94%, 57%, 1) 100%);

    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FF0F7B", endColorstr="#F89B29", GradientType=1 );

    

    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }
`;
