import React from 'react';
import styled from 'styled-components';
import LandingPageImage from "../../images/vectors/online-study-desks.png"
import { CustomText } from "../../components/text";
import { Card } from "../../components/card";
import { Navbar } from "../../components/navbar";

class FirstPage extends React.Component {
    render() {
        return (
            <FirstPagecontainer >
                <Navbar />
                <Card
                    space="center"
                    className="cards"
                >
                    <Card
                        style={{ alignSelf: "center" }}
                        margin="0em 1em"
                    >
                        <CustomText className="header" fontSize="3.5em" fontWeight="500" fontColor="#84220D">
                            Engaging and empowering students, and facilitating direct learning for everyone by everyone
                        </CustomText>
                        <CustomText fontWeight="normal" fontSize="18px" fontColor="#2e3131d6" fontLineHeight="1.5" fontMargin="10px 0">
                            Using technology to optimize teaching and learning.
                        </CustomText>
                    </Card>
                    <img src={LandingPageImage} width="50%" alt="landing page" />

                </Card>
            </FirstPagecontainer >
        );
    }
}

export default FirstPage;

const FirstPagecontainer = styled.div`
  position: relative;
  width: 100%;
  background-color: white;

    img{
        border-radius: 0.5em;

    }
    .cards{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1em;
        width: 80%;
        margin: auto;
    }
    img{
        margin: 1em auto;
    }
    @media only screen and (max-width: 800px) {
        
        .cards{
          flex-direction: column;
          width: 100%;
          text-align: center;
        }
        img{
          width:80%;
        }
        .header{
            font-size: 2.5em !important;
        }
      }

`