
import React from "react";
import styled from "styled-components";
import { Card } from "../../components/card";
import { CustomText } from "../../components/text";

class Statistics extends React.Component {
    render = () => {
        return (
            <StatisticsContainer>
                <CustomText fontSize="34px" textAlign="center" fontMargin="1em">
                    Let's run the numbers
                </CustomText>
                <Card display="flex" flexFlow="wrap" space="space-evenly">

                    <div
                        className="statisticsCard"
                    >
                        <div className="container">
                            <CustomText color="black" fontSize="70px" fontMargin="revert">

                                70%
                            </CustomText>
                            <CustomText fontWeight="600" fontSize="30px" fontMargin="revert" fontColor="#2e3131d5">
                                Reduction
                            </CustomText>
                            <CustomText fontWeight="normal" fontColor="#2e313194" fontSize="15px" fontMargin="revert">
                                in time spent to overcome learning challenges.
                            </CustomText>
                        </div>
                    </div>

                    <div
                        
                        className="statisticsCard"
                    >
                        <div className="container">
                            <CustomText color="black" fontSize="70px" fontMargin="revert">
                               30%
                            </CustomText>
                            <CustomText fontWeight="600" fontSize="30px" fontMargin="revert" fontColor="#2e3131d5">
                                Increase
                            </CustomText>
                            <CustomText fontWeight="normal" fontColor="#2e313194" fontSize="15px" fontMargin="revert">
                                student culture of learning from each other
                            </CustomText>
                        </div>
                    </div>

                    <div
                       
                        className="statisticsCard"
                    >
                        <div className="container">
                            <CustomText color="black" fontSize="70px" fontMargin="revert">
                                50%
                            </CustomText>
                            <CustomText fontWeight="600" fontSize="30px" fontMargin="revert" fontColor="#2e3131d5">
                                Boost
                            </CustomText>
                            <CustomText fontWeight="normal" fontColor="#2e313194" fontSize="15px" fontMargin="revert">
                                in overall student's productivity
                            </CustomText>
                        </div>
                    </div>

                </Card>
            </StatisticsContainer>

        )
    }
}

export default Statistics
const StatisticsContainer = styled.div`
    width: 100%;
    text-align: center;

    .statisticsCard{

        width: 20%;
        margin: 1em 1em 25px;
        padding: 0 8px;
        height: 300px;
        border-radius:15px;
        transition :all ease 200ms;
        background:#ECECEC;
        border:1px solid #ffffff0d;
        align-items: center;
            
        &:hover{
            div{
                h1{
                    color: #d5b810;
                }
                transition: .3s cubic-bezier(.6,.4,0,1);
                background: #fbf5d0;
            }

            transform: scale(1.03);
            border-radius: 10px;
            cursor: pointer;
            box-shadow:  16px 16px 44px #0a0a0a10, 
                         -16px -16px 44px #282a2810;
            transition: 0.3s all ease-in-out;
            background: #fbf5d0;      
            
        } 
    }


    .container{
        background:transparent;
        margin: auto;
        :hover{
            transform: scale(1.01);
            cursor:pointer;
            border-radius:10px;
            transition: 0.3s;
        }

    }

    
    @media screen and (max-width: 700px) {
        .cards{
            flex-direction: column;
        }

        .statisticsCard{
            width: 90%;
            padding: 0px
        }
      }
    .container::after, .cards::after{
        content: "";
        clear: both;
        display: table;
    }
   
`