import React from "react";
import styled from "styled-components";
import Footer from "../../components/footer/index";
import { Marginer } from "../../components/marginer";
import { GradientDescription } from "./gradientDescription";
// import { MoreAboutSection } from "./moreAboutSection";
import { ServicesSection } from "./servicesSection";
import FirstPage from "./landingPage";
import Statistics from "./statistics";
import TeacherIntroSection from "./teacherIntroSection";
import OurMission from "./ourMission";


const PageContainer = styled.div`
  
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function Homepage(props) {
  return (
    <PageContainer>
      {/* <TopSection /> */}
      <FirstPage />
      <ServicesSection />
      <Marginer direction="vertical" margin="2em" />
      <GradientDescription />
      <OurMission/>
      <Statistics />
      <TeacherIntroSection />
      {/* <MoreAboutSection /> */}
      <Marginer direction="vertical" margin="8em" />
      <Footer />
    </PageContainer>
  );
}
