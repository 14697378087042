import React from "react";
import { Card } from "../../components/card";
import { CustomText } from "../../components/text";
import VideoPlayerIcon from "../../images/icons/video-player.svg";
import StudyIcon from "../../images/icons/clipboard.svg";
import GetValueIcon from "../../images/icons/save-money.svg";
import GetThingsDoneIcon from "../../images/icons/checked.svg";
import styled from "styled-components";


export function GradientDescription(props) {
  return (
    <GradientDescriptionContainer>
      <CustomText fontSize="34px" textAlign="center" fontMargin="1em">
        In the simplest terms, what is Skideo?
      </CustomText>
      <CustomText fontWeight="normal" fontSize="20px" fontMargin="1em" textAlign="center">
      Skideo is an e-learning platform where teachers can teach expressively, and students can access uploaded content by teachers.
      </CustomText>
      <CustomText
        color="#1b1f23b3"
        fontWeight="bold"
        fontSize="15px"
        fontMargin="1em 0 0"
        textAlign="center">
        Even more simpler
      </CustomText>
      <Card className="gradientCardContainer">

        <GradientCard
          className="gradientCard"
          image={VideoPlayerIcon}
          title="Consume"
          subtitle="Get the best version of concept explained in a concise manner.
           The platform is designed to provide more than a classroom experience."
          background="linear-gradient(90deg, #fb501d0d 0%, #fb24910d 100%)"
        />
        <GradientCard
          className="gradientCard"
          image={StudyIcon}
          title="Assess"
          subtitle="Get assessed on what you are studying and get help in doing so."
          background="linear-gradient(45deg, #1fc3a81a 0%, #fed32f1a 100%)"
        />
        <GradientCard
          className="gradientCard"        
          image={GetValueIcon}
          title="Value"
          subtitle="Get the best value for your education. Straight to the point, and great use of time."
          background="linear-gradient(45deg, #1ca0fd1a 0%, #1fc3a81a 100%)"
        />
        {/* TODO: collaborate instead of get things done */}
        <GradientCard
          className="gradientCard"
          image={GetThingsDoneIcon}
          title="Get things done"
          subtitle="In the end, everyone gets things done and is happy."
          background="linear-gradient(45deg, #1ca0fd1a 0%, #9620d81a 100%)"
        />
      </Card>

    </GradientDescriptionContainer>
  );
}


function GradientCard(props) {
  return (
    <Card
      background={props.background}
      margin="1em"
      padding="1em 2em"
      width="25%"
    >
      <img src={props.image} alt="illustration" width="46" height="46" />
      {/* text */}
      <CustomText fontWeight="700" fontSize="20px" fontMargin="10px 0" fontLineHeight="1.5">
        {props.title}
      </CustomText>
      <CustomText fontWeight="normal" fontSize="15px" fontMargin="5px 0 10px" fontLineHeight="1.5">
        {props.subtitle}
      </CustomText>
      {/* text */}
    </Card>
  );
}


const GradientDescriptionContainer = styled.div`
  display: block;
  .gradientCardContainer{
    display:flex;
    justify-content: center;
    width: 80%;
    align-self: center;
    margin: 2em auto;

  }

  @media only screen and (max-width: 700px) {
    display:block;
    div{
      width: initial;
    }
    .gradientCardContainer{
      flex-direction: column;
    }
  }

`