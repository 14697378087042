import React from "react";
import styled from "styled-components";

class NewNavBar extends React.Component {
  render() {
    return (
      <NewNavBarContainer> 
      {/* the above was initially className="resp__bo" */}
        <div id="navstyle" className="navstyle">
          <ul>
            <li className="logo">
              <a className="logo" href="#home">
                Skideo
              </a>
            </li>
            {/* <li className="nonlogo" className="resp__bo">
              <a href="/teacher">Teacher</a>
            </li> */}
            <li>
                <a className="getstarted" href="https://skideo.tv" rel="noreferrer" target="_blank"> Get Started</a>
            </li>
          </ul>
        </div>
      </NewNavBarContainer>
    );
  }
}

export default NewNavBar;

const NewNavBarContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&family=Tienne:wght@700&display=swap");

  .getstarted{
    color: #923d41;
    background-color: #923d4115;
    border-radius: 10px;
  }
  .navstyle {
    /* Safari */
    position: relative;
    top: 0;

    width: 100%;
    position: fixed;
  }

  @media screen and (max-width: 768px) {
    .navstyle li {
      width: 100%;
    }
  }

  .logo {
    color: #5e0d0d !important;
    font-weight: bold !important;
  }

  .logo {
    color: #5e0d0d;
    font-weight: bold;
  }
  .logo:hover {
    background-color: white;
    color: #5e0d0d;
  }

  li {
    font-size: 1.5em;
  }
  li a:hover {
    background-color: #f1edec;
    border-radius: 10px;
  }

  li a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    margin: 0.5em;
  }

  ul {
    list-style-type: none;
    padding: 0;
    background-color: #fffffc;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2.76726px 2.21381px rgb(0 0 0 / 7%),
      0 6.6501px 5.32008px rgb(0 0 0 / 4%),
      0 12.5216px 10.0172px rgb(0 0 0 / 3%),
      0 22.3363px 17.869px rgb(0 0 0 / 3%),
      0 41.7776px 33.4221px rgb(0 0 0 / 2%), 0 100px 80px rgb(0 0 0 / 2%),
      0 0 0 1px rgb(0 0 0 / 5%);
    width: 90%;
    margin: 1em auto;
    border-radius: 7px;
  }

  .floatnavbar ul {
    display: flex;
    justify-content: space-around;
  }
`;
