import * as React from "react"
import { Homepage } from "../containers/homepage";
import NavBar from "../components/navbar/navbar";

/* DEBUG: 
}
02:50:12.557	   7 |     if (isProduction) {
02:50:12.557	>  8 |         throw new Error(prefix);
02:50:12.557	     | ^
02:50:12.557	   9 |     }
02:50:12.557	  10 |     throw new Error(prefix + ": " + (message || ''));
02:50:12.557	  11 | }
Solved by eliminating this line below:

// import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
*/

import "./index.css"
import HeaderMeta from "../components/metatags/head";

// markup
const IndexPage = () => {
  return (
    <>
    <HeaderMeta />
      <NavBar />
      <Homepage />

    </>
  )
}

export default IndexPage;
